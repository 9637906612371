import React, {useEffect, useReducer, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Table, TableBody, TableCell, TableHead, TableRow, IconButton, Tooltip} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Span from "@jumbo/shared/Span";
import { getMedicineCategoryList,getMedicineCategoryData } from '../../redux/actions/medicineCategoryActions';
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import Link from '@mui/material/Link';
import { MEDICINE_CATEGORY_LIST } from 'app/utils/constants/medicine-category';
import EditIcon from '@mui/icons-material/Edit';
import { successNotification, errorNotification } from 'app/utils/alertNotificationUtility';
import { StyledTableRow, StyledTableCell } from "@jumbo/vendors/sweetalert2/hooks";
import { DataGrid, gridClasses } from '@mui/x-data-grid';

const Category = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [scroll, setScroll] = useState('paper');    
    const patientList = useSelector((state)=> state.medicineCategory[MEDICINE_CATEGORY_LIST]);
    const rows = patientList && patientList.length > 0 ? patientList.map((row, i)=> ({...row, id:row._id, no:i+1})) : [];

    const columns = [
        { field: 'no', headerName: '#', width: 40 },
        { field: 'name', headerName: 'Category', width: 350 },        
        { field: 'description', headerName: 'Description', width: 280 },
        { field: "action", headerName: "Action", width:80, renderCell:(params)=>(
            <Span sx={{whiteSpace: 'nowrap'}}>
                <Link underline={"none"} component={RouterLink} to={`/medicine/category/manage/${params.row._id}`}>
                    <IconButton>
                        <EditIcon sx={{fontSize: 16}} />
                    </IconButton>
                </Link>
            </Span>
        ),
        sortable: false,
        disableColumnMenu: true
    }
    ];
    useEffect(()=>{
        dispatch(getMedicineCategoryList());
    },[]);

   
    const handleManagePatient = (pId) => {
        dispatch(getMedicineCategoryData({_id: pId},(resData)=>{
            navigate(`/medicine/category/manage/${pId}`)
        }))
    }

    return (
        <>
            <JumboCardQuick
                title={`Medicine Category List`}
                subheader={`List of Medicine Category patients`}
                action={
                    <Tooltip
                        title={`Medicine Category List`}
                        arrow
                        placement={"top"}
                        sx={{
                            '& .MuiTooltip-arrow': {
                                marginTop: '-0.65em'
                            }
                        }}
                    >
                        <Link underline={"none"} component={RouterLink} to={`/medicine/category/manage`}>
                            <IconButton>
                                <AddIcon/>
                            </IconButton>
                        </Link>
                    </Tooltip>

                }
                wrapperSx={{p: 0}}
            >
                {   
                    <React.Fragment>
                        
                        <DataGrid
                            rows={rows} 
                            columns={columns}
                            rowHeight={40}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 0.3,
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 20 } },
                            }}
                            pageSizeOptions={[5, 10, 20, 30, 50]}
                        />
                    </React.Fragment>
                }
            </JumboCardQuick>
            
        </>
    );
};

export default Category;
