// Import statements...
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { getUserUpdateProfileData,updateUser } from 'app/redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_USER } from 'app/utils/constants/user';
import { successNotification, errorNotification } from 'app/utils/alertNotificationUtility';

const Profile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const updateProfileData = useSelector((state) => state.user[AUTH_USER]);
    const [updateprofile, setUpdateProfile] = useState();

    console.log(updateprofile, "updateprofile");
    useEffect(() => {
        dispatch(getUserUpdateProfileData());
        return () => dispatch({ type: AUTH_USER, payload: [] });
    }, [dispatch]);

    useEffect(() => {
        if (updateProfileData) {
            setUpdateProfile(updateProfileData);
        }
    }, [updateProfileData]);
    
    const handleSubmit = async (values) => {
        if (updateprofile._id) {
            dispatch(updateUser({
                ...values,
                _id: updateprofile._id
            }, (insRS) => {
                if (insRS.success) {
                    successNotification((insRS && insRS.message ? insRS.message : 'Profile updated successfully'));
                } else {
                    errorNotification(insRS && insRS.message ? insRS.message : "Something went wrong.")
                }
            }));
        }
    };
    return (
        <React.Fragment>
            <Typography variant="h1" mb={3}>Update Profile</Typography>
            <Card sx={{ display: 'flex', mb: 3.5 }}>
                <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                    <CardContent>
                        <Typography variant="h6" color={"text.secondary"}>Update Profile</Typography>
                        <Typography component={"h2"} variant="h1" mb={3}>Manage your profile</Typography>
                        {updateprofile && Object.keys(updateprofile).length > 0 && (
                        <Formik
                            initialValues={{
                                first_name: updateprofile &&  updateprofile?.first_name ? updateprofile?.first_name : '',
                                last_name: updateprofile && updateprofile?.last_name ? updateprofile?.last_name : '',
                                middle_name: updateprofile && updateprofile?.middle_name ? updateprofile?.middle_name : '',
                                email: updateprofile && updateprofile?.email ? updateprofile?.email :'',
                                mobile: updateprofile && updateprofile?.mobile ? updateprofile?.mobile : '',
                                password: ''
                            }}
                            onSubmit={handleSubmit}
                        >
                            <Form>
                                <Box
                                    sx={{
                                        mx: -1,
                                        '& .MuiFormControl-root:not(.MuiTextField-root)': {
                                            p: 1,
                                            mb: 2,
                                            width: { xs: '100%', sm: '50%' }
                                        },
                                        '& .MuiFormControl-root.MuiFormControl-fluid': {
                                            width: '100%'
                                        }
                                    }}
                                    autoComplete="off"
                                >
                                    <FormControl>
                                        <JumboTextField
                                            fullWidth
                                            id="first_name"
                                            name="first_name"
                                            label="First Name"
                                            variant="outlined"
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <JumboTextField
                                            fullWidth
                                            id="middle_name"
                                            name="middle_name"
                                            label="Middle Name"
                                            variant="outlined"
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <JumboTextField
                                            fullWidth
                                            id="last_name"
                                            name="last_name"
                                            label="Last Name"
                                            variant="outlined"
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <JumboTextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Email"
                                            variant="outlined"
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <JumboTextField
                                            fullWidth
                                            id="mobile"
                                            name="mobile"
                                            label="Phone No."
                                            variant="outlined"
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <JumboTextField
                                            fullWidth
                                            id="password"
                                            name="password"
                                            label="Password"
                                            variant="outlined"
                                        />
                                    </FormControl>
                                    <Div sx={{ mx: 1 }}>
                                        <Button type="submit" variant={"contained"}>Submit</Button>
                                    </Div>
                                </Box>
                            </Form>
                        </Formik>
                        )}
                    </CardContent>
                </Div>
            </Card>
        </React.Fragment>
    );
};

export default Profile;
