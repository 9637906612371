import React, { useState } from 'react';
import { Card, CardContent, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { successNotification, errorNotification } from 'app/utils/alertNotificationUtility';
import { useDispatch } from 'react-redux';
import { AUTH_ACCESS } from 'app/utils/constants/user';
import logo from "assets/bharuch-health.png";
import { forgotPassword } from '../../../redux/actions/userActions';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
});

const Login2 = ({ disableSmLogin }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState(false)
    const onSignIn = (email, password, cb) => {
        try {
            dispatch(forgotPassword({ type: AUTH_ACCESS, email: email }, (resData) => {
                if (resData && resData.success) {
                    successNotification(resData?.message ? resData?.message : "Link generated successfully, please check your registered email.");
                    setData(true)
                    setTimeout(() => {
                        navigate("/user/login");
                    }, 800)
                } else {
                    setData(false)
                    errorNotification(resData?.message ? resData?.message : "Something went wrong");
                }
            }));
        } catch (error) {
            console.error("An error occurred while calling forgotPassword:", error);
        }
    };

    return (
        <Div sx={{
            width: 790,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/keith-luke.jpg`, "640x428")}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', .65)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>Reset
                                Password</Typography>
                            <Typography variant={"body1"} mb={2}>
                                By entering your registered email address you will receive reset password link, Kindly
                                follow instruction.
                            </Typography>
                            <Typography variant={"body1"}>
                                <Link
                                    to={"/user/login"}
                                    style={{ color: "white", textDecoration: "none" }}
                                >Login Now
                                </Link>
                            </Typography>
                        </Div>

                        <Div sx={{ mt: 'auto' }}>
                            <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
                                <img style={{
                                    maxWidth: '120px'
                                }} src={logo} alt="Jumbo React" />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{ flex: 1, p: 4 }}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            onSignIn(data.email, data.password, () => { setSubmitting(false); });

                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form action='/dashboard' style={{ textAlign: 'left' }} noValidate >
                                <Div sx={{ mt: 1, mb: 3 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Email"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={data}
                                >Send</LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login2;
