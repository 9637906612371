import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import AddIcon from '@mui/icons-material/Add';
import Span from "@jumbo/shared/Span";
import { getPHcList } from '../../redux/actions/phcActions';
import { Link as RouterLink } from "react-router-dom";
import Link from '@mui/material/Link';
import { PHC_LIST } from 'app/utils/constants/phc';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

const PHc = (props) => {
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getPHcList());
    }, []);

    const patientList = useSelector((state) => state.phc[PHC_LIST]);
    const rows = patientList && patientList.length > 0 ? patientList.map((row, i) => ({ ...row, id: row._id, no: i + 1 })) : [];
    const columns = [
        { field: 'no', headerName: '#', width: 40 },
        { field: 'name', headerName: 'Name', width: 350 },
        { field: 'phc_number', headerName: 'PHc Number', width: 280 },
        { field: 'address', headerName: 'Address', width: 280 },
        {
            field: "action",
            headerName: "Action",
            width: 80,
            renderCell: (params) => (
                <Span sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" component={RouterLink} to={`/phc/manage/${params.row._id}`}>
                        <IconButton>
                            <EditIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Link>
                </Span>
            ),
            sortable: false,
            disableColumnMenu: true
        }
    ];
    return (
        <>
            <JumboCardQuick
                title={`PHc List`}
                subheader={`List of PHc`}
                action={
                    <Tooltip
                        title={`PHc List`}
                        arrow
                        placement={"top"}
                        sx={{
                            '& .MuiTooltip-arrow': {
                                marginTop: '-0.65em'
                            }
                        }}
                    >
                        <Link underline={"none"} component={RouterLink} to={`/phc/manage`}>
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>

                }
                wrapperSx={{ p: 0 }}
            >
                {
                    <React.Fragment>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            rowHeight={40}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 0.3,
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 20 } },
                            }}
                            pageSizeOptions={[5, 10, 20, 30, 50]}
                        />
                    </React.Fragment>
                }
            </JumboCardQuick>

        </>
    );
};

export default PHc;
